/* Scaffolding
/* ------------------------- */

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: transparent;
  padding-bottom: 20px;
  line-height: 1.57142857;
  color: #000;
  font-weight: 400;
  font-size: 15px;
  /* -webkit-font-smoothing: antialiased; */
  /* -webkit-text-size-adjust: 100%; */
}
/* html, body, #app { height: 100%;} */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

a {
  color: #000;
}

a:hover {
  text-decoration: none;
}

a:hover,
a:focus {
  color: #333;
}
footer {
  position: relative;
  width: 100%;
  padding: 10px;
  padding-bottom: 30px;
  vertical-align: middle;
  bottom: 0;
  border-top: 0px solid #e0e0e0;
}

footer .navbar {
  position: relative;
  margin: 0px;
}
footer.navbar-default .navbar-nav > li > a {
  padding-top: 5px;
  padding-bottom: 5px;
}

.login-container {
  max-width: 555px;
}

/* Alerts
/* ------------------------- */

.alert {
  border-width: 0 0 0 3px;
}

.alert-info {
  border-color: #31708f;
}

.alert-success {
  color: #fff;
  background-color: #29b35b;
  border-color: #1d8141;
}

.alert-danger {
  color: #b61b17;
  background-color: #ffcdd2;
  border-color: #b61b17;
}

/* Navbar
/* ------------------------- */

.navbar-default {
  background-color: #fff;
  border-color: #e0e0e0;
  border: 0;
}

.navbar-default .navbar-nav {
  color: #757575;
}

.navbar-default .navbar-nav > li > a {
  color: #262626;
  border-bottom: 1px solid transparent;
  padding-top: 17.5px;
  padding-bottom: 14.5px;
  transition: all 0.25s;
}
#footer .navbar-default .navbar-nav > li > a{
  padding: 5px;
  padding-top: 10px;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a.active {
  background-color: transparent;
  border-bottom-color: #000;
}

.navbar-default .navbar-nav > li > a.navbar-avatar {
  padding-top: 13px;
  padding-bottom: 10px;
}

.navbar-default .navbar-nav > li > a.navbar-avatar img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.navbar-default .navbar-nav > li.active > a {
  border-bottom-color: #3f51b5;
  background-color: transparent;
  color: #262626;
}

.navbar .navbar-nav > li > a.active {
  border-bottom-color: #262626;
  background-color: transparent;
  color: #262626;
}

.navbar-brand {
  /* padding: 5px; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar-default .navbar-toggle {
  border: none;
  margin-right: 0px;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #000;
}
/* Panels
/* ------------------------- */

.panel {
  border: solid 1px rgba(160, 160, 160, 0.3);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.panel-heading {
  padding: 20px 30px;
}

.panel-heading + .panel-body {
  padding-top: 0;
}

.panel-body {
  padding: 15px;
}

.panel-body h3 {
  margin-top: 0;
}

.panel-title {
  font-size: 18px;
  color: #424242;
}

/* Buttons
/* ------------------------- */

.btn-toolbar > .btn {
  float: none;
  margin-bottom: 5px;
}

.btn {
  font-weight: 600;
  border-radius: 3px;
}

.btn:focus {
  outline: 0 !important;
}

.btn-default {
  border-color: #ccc;
  background: #f5f5f5 linear-gradient(to bottom, #fff, #f5f5f5);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    inset 0 -1px 4px rgba(151, 159, 175, 0.2);
}

.btn-default:active {
  background: #e6e6e6 linear-gradient(to bottom, #f0f0f0, #e6e6e6);
}

.btn-success {
  border-color: #208a46;
  background: #29b35b linear-gradient(to bottom, #2ec866, #29b35b);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 4px rgba(32, 138, 70, 0.3);
}

.btn-success:active {
  background: #239a4e linear-gradient(to bottom, #28af59, #239a4e);
}

.btn-danger {
  border-color: #8c1512;
  background: #b61b17 linear-gradient(to bottom, #cc1e1a, #b61b17);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 4px rgba(140, 21, 18, 0.3);
}

.btn-danger:active {
  background: #9b1714 linear-gradient(to bottom, #b11a17, #9b1714);
}

.btn-facebook {
  color: #fff;
  border-color: #335190;
  background: #3b5998 linear-gradient(to bottom, #4263a9, #3b5998);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 4px rgba(59, 89, 152, 0.3);
}

.btn-facebook:hover,
.btn-facebook:focus {
  color: #fff;
}

.btn-twitter {
  color: #fff;
  border-color: #009fdb;
  background: #00aced linear-gradient(to bottom, #05baff, #00aced);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 4px rgba(0, 172, 237, 0.3);
}

.btn-twitter:hover,
.btn-twitter:focus {
  color: #fff;
}

.btn-google {
  color: #fff;
  border-color: #d54331;
  background: #dd4b39 linear-gradient(to bottom, #e05d4d, #dd4b39);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 4px rgba(221, 75, 57, 0.3);
}

.btn-google:hover,
.btn-google:focus {
  color: #fff;
}

.btn-vk {
  color: #fff;
  border-color: #4f7192;
  background: #587ea3 linear-gradient(to bottom, #678aad, #587ea3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 4px rgba(79, 113, 146, 0.3);
}

.btn-vk:hover,
.btn-vk:focus {
  color: #fff;
}

.btn-github {
  color: #fff;
  border-color: #444;
  background: #545454 linear-gradient(to bottom, #616161, #545454);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.1),
    inset 0 -1px 4px rgba(68, 68, 68, 0.3);
}

.btn-github:hover,
.btn-github:focus {
  color: #fff;
}

/* Forms */
/* ------------------------- */

textarea {
  resize: none;
}

.description{
  padding-top: 10px;
  padding-bottom: 10px;
}
.section-block{
  padding-bottom: 40px;
  position: relative;
}
.form-control {
  height: auto;
  padding: 7px 12px;
  border: 0px solid #ebebeb;
  border-bottom: 1px solid #000;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(150, 160, 175, 0.1),
    inset 0 1px 15px rgba(150, 160, 175, 0.05);
  box-shadow: none;
  padding-left: 3px;
  padding-right: 3px;
  color: #000;
  background: none;
}
textarea#about.form-control {
  border: 1px solid #000;
  resize: vertical;
}
#dashboard-report-range .form-control {
  padding: 8px 12px;
}
#basic-addon2.input-group-addon {
  padding: 5px 12px;
}
#orders-filters #basic-addon2.input-group-addon{
  border: 0px;
  background: none;
 }
 #orders-filters .form-control[readonly]{
  background: none;
  text-align: center;
 }
.hr-title {
  height: auto;
  margin-top: 20px;
  border-top: 1px solid;
  color: #bbb;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
}

.hr-title span {
  position: relative;
  padding: 2px 10px;
  top: -10px;
  background-color: #fff;
  border-radius: 2px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

#price {
  padding: 5px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  height: 50px;
}

#loader {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
  opacity: 0.8;
  filter: alpha(opacity=60);
  z-index: 9999;
  top: 0;
}
#loader > div {
  top: 48%;
  left: 48%;
  position: fixed;
  z-index: 10000;
}
.releases-container{
  background-color: #F9F9F9;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;  
}

.release-item{
  padding-bottom: 15px;
  padding-top: 15px;
  background-color: #FFF;
  margin: 0px;
  border-radius: 5px;
}

.variation-container{
  background: transparent;
}

.color-variant-item{
  margin: 2px;
  padding: 2px;
  border: 1px solid transparent;
  border-radius: 0px;
  /* background-color: #eee; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;  
}
.color-variant-item a{
  display: block;
  text-align: center;
  align-items: center;
}
.color-variant-item a img{
  margin: 0 auto;
}
.color-variant-item.current{
  border-color: #222;
}

.release-item,
.release-item .release-price-from,
.release-item .release-item-image,
.release-item .release-item-title,
.release-item .release-price-dicount {
  display: block;
}

.release-item-wrapper {
  position: relative;
}
.release-item-continer{
  padding: 0px;
  padding-left: 8px;
  padding-top: 8px;
}

.release-item-image {
  position: relative;
  vertical-align: top;
  padding: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;
  vertical-align: top;
  margin-top: 50px;
  margin-bottom: 50px;
  display:table-cell;
  vertical-align: middle;
  min-height: 140px;
  /* border-bottom: 1px solid #ccc; */
}

.release-style-code {
  min-height: 18px;
  font-size: 12px;
  color: #666;
}
.release-item-title {
  height: 45px;
  overflow: hidden;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.release-item-title a:hover,
.release-item-title a:focus {
  text-decoration: none;
}

.release-item.show-date .release-item-title{
  text-align: left;
}
#pagination-wrapper{
  margin-top: 20px;
}
#pagination-wrapper .pager{
  margin: 10px 0;
}
h1 {
  font-size: 28px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 18px;
}

.td-text-align {
  text-align: right;
  font-weight: bold;
}

.page-header {
  margin-top: 0px;
  border-bottom: 0px;
}
.page-header h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.order-item {
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 20px;
  padding-left: 8px;
  padding-right: 8px;
}

.order-item .media-heading {
  font-size: 18px;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 1px solid #ccc;
}

.order-date {
  text-align: right;
  font-size: 16px;
  padding-left: 0px;
}

.order-item-status {
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid #ccc;
  clear: both;
  margin-top: 8px;
}
#orders-wrapper .col-md-6,
#orders-wrapper .col-sm-6 {
  padding-left: 10px;
  padding-right: 10px;
}
#orders-wrapper.row,
#orders-wrapper .row,
#orders-filters.row,
#orders-filters .row  {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 15px;
}
#orders-filters .form-horizontal .form-group{
  margin-left: 0px;
  margin-right: 0px;
}

.item-txt {
  font-weight: bold;
}

.text-right {
  text-align: right;
}

.size-item {
  cursor: pointer;
  margin: 1px;
  border: 1px solid transparent;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #222;
  display: block;
  background-color: #f4f4f4;
  border-radius: 5px;
}

.size-item:hover {
  border-color: #000;
}

.size-item.selected {
  background-color: #000;
  color: #fff;
}
#release-size {
  margin-bottom: 10px;
}

#release-size .nav-tabs {
  border-bottom: 1px solid transparent;
  margin-bottom: 8px;
}
#release-size .nav-tabs > li > a {
  border-radius: 0px;
  font-weight: 300;
  margin-right: -1px;
  user-select: none;
  outline: 0;
  border: none;
  border-bottom: 1px solid #fff;
  color: #555;
  border-bottom: 1px solid #ccc;
}
#release-size .nav-tabs > li {
  margin-bottom: 0px;
}
#release-size .tab-content {
  background-color: #fff;
}

#release-size .nav-tabs > li.active > a {
  background: none;
  border-bottom: 1px solid #000;
  color: #000;
}
#release-size .nav-tabs>li>a:hover{
  background: none;
  border-bottom: 1px solid #000;
  color: #000;
}

h1.page-title {
  margin-top: 0px;
  font-size: 24px;
  font-weight: 500;
}
.page-title>h1{
  text-align: center;
}
.clear-paddings {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.clear-padding-left {
  padding-left: 0 !important;
}
.clear-padding-right {
  padding-right: 0 !important;
}

.clear-margins {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.clear-margin-left {
  margin-left: 0 !important;
}
.clear-margin-right {
  margin-right: 0 !important;
}

.btn.btn-snkrly {
  box-shadow: none;
  font-size: 14px;
  align-items: flex-start;
  text-decoration: none !important;
  float: none;
  margin: 0px;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  font-weight: 400;
  background: 0 0;
  line-height: 1.5;
  cursor: pointer;
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
  background-color: #fff  !important;
  color: #111  !important;
  outline: 0;
  border: 1px solid #ccc;
  padding: 5px 24px !important;
  border-radius: 8px  !important;
  text-transform: capitalize;
}

.btn.btn-snkrly:hover,
#pagination-wrapper .pager li>a:hover,
#pagination-wrapper .pager li>a:active,
#pagination-wrapper .pager li>a:focus {
  background-color: rgba(0,0,0,.75) !important;
  color: #fff !important;
  border-color: rgba(0,0,0,.75) !important;
  text-decoration: none;
  box-shadow: none;
  -webkit-box-shadow: none;

}

.btn.btn-snkrly-dark, #pagination-wrapper .pager li>a, #pagination-wrapper .pager li>span{
  align-items: flex-start;
  text-decoration: none !important;
  float: none;
  margin: 0px;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  text-align: center;
  font-weight: 400;
  background: 0 0;
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
  background-color: #111  !important;
  color: #FFF  !important;
  outline: 0;
  border: 1px solid #111;
  padding: 5px 24px;
  border-radius: 8px !important;
  text-transform: capitalize;
}

.btn.btn-snkrly-dark:hover,
.btn.btn-snkrly-dark:active, 
.btn.btn-snkrly-dark:focus{
  background-color: rgba(255,255,255,.75);
  color: #111;
  border-color: #ccc;
  text-decoration: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.btn-invite {
  border-radius: 0px !important;
  background: rgba(255, 255, 255, 0.8) !important;
  border: 0px solid #000 !important;
  color: #000 !important;
  padding: 10px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  font-size: 16px !important;
  font-weight: 400;
}
.btn-invite:hover {
  background: rgba(0, 0, 0, 0.6) !important;
  color: #fff !important;
  border-color: #fff !important;
  border: 0;
}

.applyBtn.btn-snkrly, 
.cancelBtn.btn-snkrly{
  display: inline-block !important;
  padding: 5px 20px  !important;
  font-size: 12px;
  line-height: initial;
}
.form-item {
  margin-bottom: 8px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #333;
  color: #fff;
  border-color: #000;
}
.pagination > li > a,
.pagination > li > span {
  color: #000;
  border-color: #000;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0px;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  border-color: #000;
}

.btn-remove {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  font-size: 15px;
}
.addressItemWrapper {
  font-size: 13px;
  padding: 3px;
}

#shopping-cart .table > thead > tr > th,
#shopping-cart .table > tbody > tr > th,
#shopping-cart .table > tfoot > tr > th,
#shopping-cart .table > thead > tr > td,
#shopping-cart .table > tbody > tr > td,
#shopping-cart .table > tfoot > tr > td {
  border-top: 1px solid #222;
}
#shopping-cart .table > tbody > tr > td:last-child {
  text-align: right;
}
#shopping-cart .table > tbody > tr > td {
  vertical-align: middle;
}
#shopping-cart .Select-control {
  border-bottom: 0px !important;
}
#shopping-cart .table > thead > tr > th {
  border-bottom: 1px solid #222;
}
#shopping-cart .table {
  margin-bottom: 8px;
}

#checkout-form.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
  padding: 0;
}
.help-block {
  color: #222;
}

#estimate_delivery {
  display: block;
  height: 20px;
  margin-bottom: 0px;
  position: absolute;
  bottom: 10px;
  overflow: hidden;
  z-index: 0;
  width: 100%;
  text-align: center;
}


/* Image Gallery */
.image-gallery-thumbnail {
  border: 2px solid transparent;
}
.image-gallery-thumbnail.active {
  border: 2px solid #000;
}

.image-gallery-bullets .image-gallery-bullet.active {
  background-color: #000;
}
.image-gallery-bullets .image-gallery-bullet {
  border: 1px solid #000;
  box-shadow: 0px 0px 0px #999;
}
.image-gallery-bullets {
  bottom: -20px;
}
.image-gallery-slide {
  background: transparent;
  user-select: none;
}
.image-gallery-icon{
  color: #ccc;
  filter: none;
  padding: 0px;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 4;
  font-size: 3em;
}

.image-gallery-left-nav,
.image-gallery-right-nav{
  height: 100%;
}

.image-gallery-left-nav{
  left: -10px;
}
.image-gallery-right-nav{
  right: -10px;
}

.image-gallery-left-nav .image-gallery-svg, 
.image-gallery-right-nav .image-gallery-svg{
  width: 20px;
  height: auto;
}
.image-gallery-fullscreen-button {
  z-index: 5;
  display: none;
}
.fullscreen-modal .image-gallery-left-nav{
  left: 0;
}
.fullscreen-modal .image-gallery-right-nav{
  right: 0;
}
.fullscreen-modal .image-gallery-fullscreen-button{
  display: block;
  margin-bottom: -20px;
}

.image-gallery-fullscreen-button::before {
  content: "\f264";
}

.image-gallery-fullscreen-button.active::before {
  content: "\f25c";
}
.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before {
  font-size: 2em;
  padding: 8px;
  text-shadow: 0 0px 0px #999;
}

.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
  text-shadow: 0 0px 0px #999;
  color: #000;
}

.image-gallery-content.fullscreen {
  background: transparent;
}
.image-gallery-content.fullscreen .image-gallery-bullets .image-gallery-bullet {
  background-color: transparent;
}

.image-gallery-content.fullscreen .image-gallery-bullets .image-gallery-bullet.active {
  background: #fff;
}

.image-gallery:not(.fullscreen-modal)  .image-gallery-slide img {
  height: 400px;
}
.image-gallery.fullscreen-modal{
  background-color: #FFF;
  z-index: 11000;
}
.image-gallery-content.fullscreen .image-gallery-bullets .image-gallery-bullet.active{
  background-color: #000;
}
.image-gallery.fullscreen-modal .image-gallery-slide img {
  height: 100%;
}

.image-gallery {
  margin-bottom: 35px;
}

.image-gallery-fullscreen-button::before{
  content: none;
}

.image-gallery-index{
  top: unset;
  right: unset;
  padding-top: 0px;
  padding-bottom: 0px;
  bottom: 0;
  background-color: transparent;
  color: #111;
  margin-left: auto;
  margin-right: auto;
  left: 44.2%;
  font-size: 0.8em;
}
.image-gallery.fullscreen-modal .image-gallery-index{
  position: fixed;
  margin-bottom: -20px;
}
body.silder-fullscreen{
  overflow: hidden;
}
body.silder-fullscreen .navbar-static-top{
  z-index: unset;
}
body.silder-fullscreen .pa-global-class, 
body.filter-open .pa-global-class{
  z-index: -1 !important;
  overflow: hidden;
} 
body.silder-fullscreen .navbar-brand{
  z-index: 11000;
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  padding-left: 12px;
}
body.silder-fullscreen .navbar-static-top>.container{
  position: relative;
}

.top-nav-sticky .kf-nav-wrapper{
  padding-top: 0px;
}

@media (hover: hover)and (pointer: fine){
  .image-gallery-icon:hover {
    color: #999;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }

}

#app{
  background-color: white;
  position: relative;
  /* display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;   */
}
.kf-nav-wrapper{
  margin-top: 10px;
  margin-bottom: 10px;
}
.kf-nav-wrapper .nav-pills {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.kf-nav-wrapper>ul.nav.nav-pills.two-tabs,
.kf-nav-wrapper>ul.nav.nav-pills.three-tabs,
.kf-nav-wrapper>ul.nav.nav-pills.four-tabs{
  width: 50%;
}

.kf-nav-wrapper .nav-pills.nav-pills-wide {
  width: 100% !important;
}

.kf-nav-wrapper>ul.nav.nav-pills>li {
  float: none;
  margin: 0;
  min-width: 100px;
  display: table-cell;
  vertical-align: middle;
  padding: 5px 3px;
  letter-spacing: normal;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}

.kf-nav-wrapper>ul.nav.nav-pills>li {
  min-width: 122px;
}

.kf-nav-wrapper>ul.nav.nav-pills.two-tabs>li {
  width: 50% !important;
}
.kf-nav-wrapper>ul.nav.nav-pills.three-tabs>li {
  width: 33.33% !important;
}
.kf-nav-wrapper>ul.nav.nav-pills.four-tabs>li {
  width: 25% !important;
}

.kf-nav-wrapper>ul.nav.nav-pills.five-tabs>li {
  width: 20% !important;
  min-width: unset;
}
.kf-nav-wrapper.tags>.nav.nav-pills>li{
  padding: 2px 2px;
  min-width: unset;
  text-align: justify;
  float: none;
  display: inline-block;
}
.kf-nav-wrapper.tags .nav-pills {
  display: block;
  text-align: center;
}
#page-releases .kf-nav-wrapper.tags .nav-pills {
  display: block;
  text-align: left;
}

#releases-date-tabs h3.kf-block-title {
  text-transform: capitalize;
  margin: 0;
  margin-right: auto;
  color: #111;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.166667;
  padding: 5px 10px;
  padding-left: 0;
  position: relative;
  display: inline-table;
  letter-spacing: normal;
}
#footer .container .row{
  margin-top: 10px;
  margin-bottom: 10px;
}

.section-related-releases.type-tags .kf-block-title {
  margin-left: auto;
  margin-right: auto;
}
.btn.btn-snkly-small{
  padding: 3px 12px !important;
  border-radius: 8px !important;
  font-size: 13px;
}
#release-view-wrapper .td-tags .nav-pills li{
  padding-top: 2px;
  padding-bottom: 2px;
}

#pagination-wrapper .pager li{
  width: 50%;
  display: inline-block;
}
#pagination-wrapper .pager li>a{
  width: 100%;
  font-size: 16px;
  padding: 8px 15px !important
}

#release-view-wrapper .page-header {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

#release-view-wrapper .description, 
#page-releases  .description {
  font-size: 13px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 13px;
}
#release-view-wrapper .description a{
  font-weight: bold;
  text-decoration: underline;
}

.out-of-stock {
  border: 1px solid #000;
  padding: 8px;
  display: inline-block;
  border-radius: 0;
  color: #000;
  text-transform: uppercase;
}
.social-items-wrapper {
  text-align: center;
  margin-left: 0px;
  white-space: nowrap;
  padding-left: 15px;
}
.social-item {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}
.social-item a{
  display: block;
}
.form-control:focus {
  border-color: #000;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: none;
  -webkit-box-shadow: none;
}

input[type="text"] {
  font-size: inherit;
}

#order-messages-container {
  margin-bottom: 10px;
}

.order-message-item-wrapper {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 15px;
  margin-left: 0px;
  padding: 8px;
}

.msg-content-left {
  background-color: #fff;
  border: 1px solid #eee;
}
.msg-content-right {
  border: 1px solid #eee;
  background-color: #eee;
}
.message-date .formated-date {
  float: right;
}

.msg-author {
  display: inline-block;
  padding-right: 15px;
}

.message-date {
  white-space: nowrap;
  border-bottom: 1px solid;
}
.message-date:after {
  clear: both;
}
.message-body {
  clear: both;
  padding-top: 8px;
  padding-bottom: 8px;
}

.social-item-wrapper {
  display: inline;
  margin-left: 3px;
}

.release-price-from {
  font-weight: 400;
  color: #000;
  text-align: right;
  padding: 5px;
  padding-right: 23px;
}
.release-price-dicount{
  /* color: #f0332a; */
  /* color: #ff0000; */
  color: #AD1700;
  width: 100%;
  font-size: 90%;
  text-align: center;
  position: absolute;
  padding: 0px;
  left: 0px;
  bottom: -50px;
}

.custom-tooltip-wrapper {
  background-color: #fff;
  color: #262626;
  border: 1px solid #bbb;
}

#dashboard-report-range {
  cursor: pointer;
  font-weight: normal;
  vertical-align: top;
  margin-bottom: 8px;
  margin-top: 8px;
}
#dashboard-report-range .input-group-addon:last-child {
  border: 0;
}
#dashboard-report-range .input-group .form-control,
#dashboard-report-range .input-group .form-control:focus {
  border-color: #eee !important;
  box-shadow: none;
}
.dash-wall {
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  background: none;
}

.dash-text {
  font-size: 20px;
  color: #666;
  display: inline-block;
  white-space: nowrap;
  font-weight: 600;
}

.dash-label {
  text-transform: uppercase;
}

.dash-icon {
  float: right;
  position: absolute;
  right: 8px;
  top: 8px;
}

.tootltip-wrapper {
  padding: 8px;
  font-size: 14px;
  white-space: nowrap;
  list-style-type: none;
  margin: 0px;
}
.tootltip-label {
  font-weight: bold;
}

.clearfix {
  display: block;
  clear: both;
  content: "";
}

.form-control-static .thumbnail {
  margin-bottom: 0px;
}
.snkrly-icon {
  margin-bottom: 20px;
}
.snkrly-icons {
  margin-bottom: 20px;
}
.snkrly-icon-info {
  font-size: 20px;
}
.nowrap {
  white-space: nowrap;
}

.transparent-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: -1;
  margin-left: -20px;
}
#become-influencer {
  margin-top: 65px;
}

#pagination-wrapper {
  text-align: center;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border: none;
}

#search-icon {
  display: none;
}

.meta-info {
  font-size: 11px;
  color: #444;
  margin-bottom: 0;
  line-height: 14px;
  text-align: left;
  width: 100%;
  padding: 5px;
  padding-bottom: 0px;
}
.meta-info .td-post-date {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 3px;
}
#badge-wrapper{
  margin-bottom: 0px;
  position: relative;
}

#authentic-badge, #delivery-badge {
  cursor: pointer;
}
#delivery-badge{
  padding-left: 3px;
  padding-top: 5px;
}

.search-menu-item {
  width: 100%;
}
.navbar-right.closed {
  float: right;
}
.navbar-right.closed #search {
  display: none;
}

.navbar-right.open #search {
  display: block;
}
.navbar-right.open {
  float: none;
}

.navbar-right.open .search-menu-item {
  width: 100%;
}
.pagination > li > a,
.pagination > li > span {
  border: 0px;
  border-bottom: 1px solid #000;
}

.order-image {
  width: 100px;
  height: 80px;
  background-position: center center;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

#page-profile .form-horizontal .form-control {
  padding: 5px 5px;
}

.main-page .static-page {
  padding-top: 5em;
}

#change-word {
  font-weight: 550;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  border-bottom: 1px double #fff;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.Select-control,
.react-select__control {
  color: #000 !important;
  border: 0 !important;
  border: 0px !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0px !important;
}
.react-select__control--is-focused {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.Select-arrow,
.react-select__indicator,
.react-select__dropdown-indicator,
.react-select__indicator-separator {
  border-color: #000 transparent transparent !important;
  background: none !important;
  background-color: #000 transparent transparent !important;
  color: #000 !important;
}
.Select-option,
.react-select__option {
  box-sizing: border-box;
  background-color: #fff;
  color: #000 !important;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  border-bottom: 1px solid #000;
}
.react-select__menu {
  border-radius: 0px !important;
  margin-top: 0px !important;
  background-color: #fff;
}
.Select-menu-outer,
.react-select__menu-list {
  border: 1px solid #000 !important;
  border-radius: 0px !important;
  border-top: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.Select-option:last-child,
.react-select__option:last-child {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom: 0px;
}
.Select-option.is-selected,
.Select-option.is-focused,
.react-select__option--is-selected,
.react-select__option--is-focused {
  background-color: #000 !important;
  background-color: rgba(0, 0, 0, 0);
  color: #fff !important;
}

.Select.is-focused:not(.is-open) > .Select-control,
.react-select__control--is-focused:not(.react-select__control--menu-is-open)
  > .react-select__control {
  border-color: #000 !important;
  box-shadow: none !important;
  background: #fff !important;
}

#snkrly-wrapper:not(.main-page) .static-page.container {
  padding-left: 0px;
  padding-right: 0px;
}
.navbar {
  border-radius: 0px;
}

#become-influencer .input-group-addon {
  font-size: 20px;
}

.ql-editor {
  min-height: 100px !important;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}
.navbar-brand.navbar-brand {
  display: table-cell;
  vertical-align: middle;
}
.navbar-brand:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.navbar-brand > img {
  display: inline-block;
  max-height: 45px;
}
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover{
  background-color:unset;
}
.navbar-nav>li>.dropdown-menu{
  width: 100%;
}
.navbar-default .navbar-nav.navbar-right{
  position: relative;
}
.navbar-default .nav.navbar-nav.navbar-right>li{
  position: unset;
}

.grecaptcha-badge {
  display: none;
}
#app #header h1 {
  padding-left: 15px;
}
#free-shipping{
  background-color: #000;
  padding: 8px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

#footer  .navbar{
  margin: 0px;
}
#footer .navbar-default {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

#footer .navbar-default>:nth-child(1){order: 1; flex: auto;}
#footer .navbar-default>:nth-child(2){order: 2; flex: none;}
#footer .navbar-default>:nth-child(3){order: 3; flex: none;} 

#copyright{
  text-align: left;
}

#footer-naveigation, #copyright, #social-items{
  white-space:nowrap;
}

#copyright>p{
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px;
  padding: 0px;
}
#footer .navbar-nav, #social-items{
  float: none;
  display: inline-block; 
}
.kof-block-wrapper{
  padding-top: 10px;
  padding-bottom: 10px;
}
.kof-block-wrapper .description{
  margin-bottom: 10px !important;
}
.kof-block-headline {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding: 10px;
  vertical-align: middle;
  -webkit-box-pack: center;
  justify-content: left;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
}

.kof-block-headline>h2{
  padding: 0px;
  margin: 0px;
  font-size: 20px;
  display: table;
}

.kof-block-headline > h3.kf-block-title, #releases-date-tabs h3.kf-block-title {
  text-transform: capitalize;
  margin: 0px;
  margin-right: auto;
  color: #111;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.166667;
  padding: 5px 10px;
  padding-left: 0px;
  position: relative;
  display: inline-table;
  letter-spacing: normal;
}
.kof-block-headline>h2>span{
  font-size: 16px;
  padding-left: 5px;
}
.kof-block-headline>h2>a, .kof-block-headline>h2>span{
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}
.kof-block-headline .show-all-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin-right: 0;
  margin-left: auto;
  text-transform: capitalize;
  min-width: 100px;
  height: 40px;
  padding-top: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: right;
  -webkit-box-align: center;
  align-items: center;
}

.badge-info{
  padding-left: 3px;
}
#delivery-badge .badge-info{
  padding-left: 6px;
}


#release-content{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  align-content: center;
  flex-wrap: wrap;
}
#release-content .release-image-wrapper{
  flex: 50%;
  order: 1;
  position: sticky;
  top: 0;
  z-index: 1;
}

#release-content .product-wrapper{
  flex: 50%;
  order: 2;
}
.description .desc-read-more{
  transition: opacity 1s linear;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.description .desc-read-more.open{
  opacity: 1;
  height: auto;
  transition: opacity 1s linear;
}
.description .read-more{
  display: block;
  text-align: right;
  color: #000;
  cursor: pointer;
  text-decoration: underline;
  margin-top: -10px;
}
#release-content .release-blocks-wrapper{
  flex: 100%;
  order: 4;
}
a.model-item{
  text-decoration: underline;
}
.likes-wrapper span {
  vertical-align: inherit;
}

.likes {
  display: inline-block;
  line-height: 18px;
  margin-right: 4px;
  vertical-align: sub;
}

.like-flame-grey, .like-flame-red, .like-flame-orange {
  width: 14px;
  height: 16px;
}

.release-attibutes {
  padding: 0px;
  margin: 0px;
  display: block;
}

.release-attr-item {
  padding: 0;
  padding: 0 0px;
  line-height: 16px;
  border-bottom: 1px solid #eee;
  width: 100%;
  display: flex;
  flex-flow: row;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
}

.release-attr-item .release-attr-value,
.release-attr-item .release-attr-title {
  padding: 8px 3px;
  text-transform: capitalize;
  text-align: left;
  color: #000;

}

.release-attr-item .release-attr-title {
  font-size: 13px;
  white-space: nowrap;
  flex: initial;
}

.release-attr-item .release-attr-value {
  text-align: right;
  font-size: 15px;
  flex: auto;
}
.release-attibutes>.release-attr-item:last-child{
  border: 0px;
}

.dropdown .fa-chevron-right{
  float: right;
}
.dropdown.open .fa-chevron-right{
  transform: rotate(90deg);
}
.discover-title{
  padding: 5px;
  font-weight: bold;
}
.react-select__input, .react-select__input input{
  width: 100%;
  display: block;
}
.breadcrumb{
  background: none;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: auto;
  font-size: 13px;
  margin-bottom: 0px;
}
.breadcrumb>li+li:before{
  color: #000;
}
.breadcrumb>li a{
  color: #444;
  padding: 8px;
}
.breadcrumb>li:first-child a{
  padding-left: 0px;
}
.breadcrumb>li:last-child a{
  padding-right: 0px;
}
.breadcrumb>.active{
  color: #000;
}

#top-ad{
  height: 50px;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
}
#login-signup-continer{
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#login-signup-tabs{
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding: 20px;
  padding-top: 50px;
}
#login-signup-tabs .nav-tabs>li{
  width: 50%;
}
#login-signup-tabs .tab-content{
  padding-top: 20px;
  padding-bottom: 20px;
}
#login-signup-tabs .nav>li>a{
  text-align: center;
  border-bottom-color: #ddd;
  /* font-weight: bold; */
}

#login-signup-tabs .nav>li>a:focus, #login-signup-tabs .nav>li>a:hover {
  text-decoration: none;
  background: none;
  border-top-color: #FFF;
  border-left-color: #FFF;
  border-right-color: #FFF;

}

#login-signup-tabs .nav-tabs>li.active>a, 
#login-signup-tabs .nav-tabs>li.active>a:focus, 
#login-signup-tabs .nav-tabs>li.active>a:hover{
  border:none;
  border-bottom:1px solid #000;
  /* background-color: #000; */
  /* color: #FFF;; */
}
.slick-list::before{
  overflow-x: auto;
}
.slick-slide img{
  /* display: block; */
}
.slider-container{
  /* padding-left: 25px;
  padding-right: 25px; */
  /* height: 70px !important;
  display: block; */
}

.slick-prev, .slick-next{
  /* color: #222; */
  /* background-color: #eee; */
  padding: 3px;
  height: 100%;
}
.slick-prev:before, .slick-next:before{
  color: #222;
  font-size: 16px;
}

/* Bullets */

.bullet {
  position: relative;
  width: 2.1rem;
  height: 2.1rem;
  display: inline-block;
}

.bullet::before {
  content: '';
  position: absolute;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: #b6b6b6;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bullet.isActive::before {
  width: 0.8rem;
  height: 0.8rem;
  background-color: #222222;
}

.bulletContainerDefault {
  z-index: 2;
  display: flex;
  justify-content: center;
}

.bulletContainerInfinite {
  position: relative;
  width: 9rem;
  height: 2rem;
  overflow: hidden;
  display: inline-block;
  justify-content: center;
  white-space: nowrap;
}

.bulletMoveInfinite {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 200ms;
}

.bulletInfinite {
  position: relative;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.5rem;
  transform: scale(0.5);
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms;
  background-color: #b6b6b6;
}

.bulletInfinite.isActive {
  transform: scale(1);
  background-color: #222222;
}

.bulletInfinite.isSecondary {
  transform: scale(0.75);
  background-color: #b6b6b6;
}

/* Wrapper */

.wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  touch-action: manipulation;
}

/* ScrollSlider */
.scrollSliderContainer {
  overflow-x: auto;
  overflow-y: hidden;

  /* Hide scrollbar on Firefox */
  /* scrollbar-width: none; */

  /* Hide scrollbar on IE */
  /* -ms-overflow-style: none; */
}

/* Hide scrollbar on Chrome and Safari */
/* .scrollSliderContainer::-webkit-scrollbar {
  width: 0;
  display: none;
} */

/* Slider */
.slider {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  transition: transform 300ms;
}

.slide {
  flex: 0 0 auto;
}

/* SnapSlider */
.snapSliderContainer {
  scroll-snap-type: x mandatory;

  /* Older spec implementation (for ios9/10) */
  scroll-snap-destination: 0% 100%;
  scroll-snap-points-x: repeat(100%);

  -webkit-overflow-scrolling: touch; /* For safari */
}

.snapSliderContainer > * {
  scroll-snap-align: center;
}

/* UnevenItemsScrollSlider */
.unevenItemsContainer {
  overflow: hidden;
}

.unevenItemsWrapper {
  position: relative;
  width: auto;
  display: flex;
  flex: 0 0 auto;
  touch-action: none;
}

@media (min-width: 768px) {
  .navbar-default .navbar-form.navbar-left {
    width: 30%;
  }
  .navbar-header .navbar-form .form-control {
    width: 80%;
  }
  .navbar-form .form-group {
    display: block;
  }
  .navbar-form .form-control {
    width: 95%;
  }
  .navbar-header .navbar-form .form-group {
    display: block;
  }
  .navbar-default .navbar-header .navbar-form.navbar-left {
    width: 70%;
  }
  #page-profile .form-horizontal .control-label {
    padding-top: 10px;
    margin-bottom: 0;
    text-align: left;
  }
  #intro.main.style1 .box header {
    padding: 30px;
  }
  .dropdown .fa-chevron-right{
    display: none;
  }
}

@media (max-width: 768px) {
  .breadcrumb{
    display: none;
  }
  .navbar-default .navbar-form {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 3px;
  }
  .navbar-default .navbar-left {
    float: none;
    width: 100%;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 1px;
    margin-top: 9px;
    padding: 0px;
  }

  .navbar-header {
    text-align: center;
  }
  .navbar-default .navbar-collapse{
    position: relative;
    background-color: #fff;
    width: 100%;
  }

  .navbar-default .navbar-nav > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .navbar-default .navbar-nav > li > a {
    border: 0px;
  }
  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background-color: transparent;
  }
  .navbar-default .navbar-toggle:hover .icon-bar,
  .navbar-default .navbar-toggle:focus .icon-bar {
    background-color: #000;
  }

  footer.navbar {
    position: relative;
    margin: 0px;
  }  
  #footer .navbar-default .navbar-nav{
    margin-left: 0px;
    margin-right: 0px;
  }
  #footer .navbar-default .navbar-nav > li {
    width: auto;
    display: inline-block;
  }

  #footer .navbar-default>:nth-child(1){
    order: 1;
    flex: auto;
  }
  #footer .navbar-default>:nth-child(2){
    order: 2;
    flex: none;
  }
  #footer .navbar-default>:nth-child(3){
    order: 3;
    flex: 1 0 100%; 
  }
  .social-items-wrapper{
    padding-left: 0px;
    text-align: center;
  }
  
  .release-item {
    padding-left: 4px;
    padding-right: 4px;
  }
  #releases-date-tabs {
    white-space: normal;
    overflow: auto;
  }
  #releases-date-tabs.kf-nav-wrapper, #page-releases.popular .kf-nav-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
    overflow-y: hidden;
    padding-left: 0;
    padding-right: 0;
  }
  #releases-date-tabs>ul.nav.nav-pills{
    display: block;
  }

  #releases-date-tabs.kf-nav-wrapper .nav.nav-pills {
    white-space: normal;
    width: 100%;
  }
  #releases-date-tabs.kf-nav-wrapper h3.kf-block-title {
    text-align: center;
    display: block;
    width: 100%;
    padding: 15px;
  }
  #releases-date-tabs.kf-nav-wrapper{
    width: 100%;
  }
  #releases-date-tabs.kf-nav-wrapper>ul.nav.nav-pills
  #releases-date-tabs.kf-nav-wrapper>ul.nav.nav-pills>li {
    min-width: unset;
    width: unset;
    padding: 5px 3px;
    display: block;
    width: 100%;
    text-align: center;    
  }    

  #pagination-wrapper .pager li{
    width: 100%;
    display: inline-block;
  }
  .kf-nav-wrapper > ul.nav.nav-pills.four-tabs .kf-btn, .kf-nav-wrapper > ul.nav.nav-pills.five-tabs .kf-btn {
    padding: 5px 8px !important;
  }
  .kf-nav-wrapper > ul.nav.nav-pills.four-tabs .kf-btn, 
  .kf-nav-wrapper > ul.nav.nav-pills.five-tabs .kf-btn,
  .kf-nav-wrapper > ul.nav.nav-pills.four-tabs > li, 
  .kf-nav-wrapper > ul.nav.nav-pills.five-tabs > li {
      min-width: 90px;
  }
  .kf-nav-wrapper>ul.nav.nav-pills.two-tabs,
  .kf-nav-wrapper>ul.nav.nav-pills.three-tabs,
  .kf-nav-wrapper>ul.nav.nav-pills.five-tabs{
    width: 100%;
  }

  .kf-nav-wrapper>ul.nav.nav-pills.two-tabs>li{
    width: 50%;
  }
  .kf-nav-wrapper>ul.nav.nav-pills>li {
    float: none;
  }
  .kf-nav-wrapper > ul.nav.nav-pills.four-tabs > li, 
  .kf-nav-wrapper > ul.nav.nav-pills.five-tabs > li {
    padding: 5px 1px;
  }
  #releases-date-tabs > ul.nav.nav-pills {
    display: block;
  }

  #releases-date-tabs.kf-nav-wrapper > ul.nav.nav-pills > li {
      min-width: unset;
      width: 33.3%;
      padding: 5px 3px;
      display: block;
      float: left;
  }
  #releases-date-tabs.kf-nav-wrapper > ul.nav.nav-pills > li > a.btn.btn-snkrly {
      padding-left: 10px !important;
      padding-right: 10px !important;
  }

  #releases-date-tabs.kf-nav-wrapper > ul.nav.nav-pills > li:first-child {
      display: block;
      width: 100% !important;
      text-align: center;
  }

  #releases-date-tabs.kf-nav-wrapper h3.kf-block-title {
      text-align: center;
      display: block;
      width: 100%;
      padding: 15px;
  }
  .kf-nav-wrapper>ul.nav.nav-pills.four-tabs .btn.btn-snkrly, .kf-nav-wrapper>ul.nav.nav-pills.five-tabs .btn.btn-snkrly{
    padding: 5px 8px !important;
  }

  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    padding: 2px;
  }
  .order-item {
    padding-left: 5px;
    padding-right: 5px;
  }
  footer{
    padding: 10px;
    padding-bottom: 100px;
  }
  footer > .navbar-nav{
    margin: 0px;
  }
  footer > div.container {
    bottom: 0;
    text-align: center;
  }
  .image-gallery-left-nav, .image-gallery-right-nav{
    display: none;
  }
  .kof-block-headline .show-all-wrapper {
    min-width: 30px;
    padding-left: 0px;
  }
  #release-content{
    flex-wrap: wrap;
  } 
  #release-content .release-image-wrapper{
    flex:1 0 100%;
    order: 1;
    position: unset;
    top: unset;
  }
  
  #release-content .product-wrapper{
    flex:1 0 100%;
    order: 2;
  }
  .image-gallery.fullscreen-modal .image-gallery-index, 
  .image-gallery.fullscreen-modal .image-gallery-fullscreen-button{
    display: none;
  }
  .release-item-image{
    margin-left: 0px;
    margin-right: 0px;
    padding: 10px;
  }
  #login-signup-tabs{
    width: 100%;
  }
}