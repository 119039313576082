#filter-wrapper {
  min-height: 500px;
}

#release-header {
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

#release-header .show-filter {
  cursor: pointer;
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;  
}

#row-container {
  overflow-x: hidden;
  display: flex;
  justify-content: flex-end; 
  align-content: stretch;
  justify-self:right;
}

#column-filter {
  width: 30%;
  margin-left: -100%;
  padding-right: 0px;
  z-index: -1;
  -webkit-transition: margin-left .3s ease-in-out;
  -o-transition: margin-left .3s ease-in-out;
  transition: margin-left .3s ease-in-out;
  
}

#release-filter {
  width: 100%;
  float: right !important;
  align-items: flex-end;
  justify-content: flex-end;
  align-content:stretch ;
  -webkit-transition: width .3s ease-in-out;
  -o-transition: width .3s ease-in-out;
  transition: width .3s ease-in-out;
  background-color: #fff;
}

.open-filters #column-filter {
  z-index: 0;
  margin-left: '0%';
}
.open-filters #release-filter{
  width: 70% !important;
}

#seachKeyword {
  text-align: center;
  font-size: 20px;
}

#seachKeyword .title {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}

#seachKeyword .info {
  font-size: 14px;
  
  text-align: center;
}

#column-filter .filter-header {
  display: none;
}

#page-releases .btn-filter-wrapper-float {
  display: none;
  position: fixed;
  text-align: center;
  z-index: 999;
  right: 5px;
  top: 48%;
  padding: 0;
  margin: 0;
  width: auto;
}
.btn-filter-wrapper-float .btn.btn-snkrly-dark{
  padding: 5px 13px;
  font-size: 18px;
  line-height: 21px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
  0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);
}

#release-header .Select-option {
  padding: 3px 5px;
}

.medium-size {
  display: none;
}
.medium-size .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20000;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  background-color: rgba(0, 0, 0);
  opacity: 0;
  visibility: hidden;
  margin-bottom: 0;
}

.medium-size .overlay.open {
  opacity: 0.6;
  display: flex;
  visibility: visible;
}

#sortByContainer .control-label {
  text-align: right;
  white-space: nowrap;
  margin: 0px !important;
  font-weight: 500;
  padding: 0px;
}
#sortByContainer .form-group{
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-bottom: 0;
}

#filter-wrapper .filter-group-button.row {
  margin-left: 0px;
  margin-right: 0px;
}
#filter-wrapper .filter-group-button button {
  width: 100%;
}

#filter-wrapper .filter-group-button [class*=col-] {
  padding-left: 2px;
  padding-right: 2px;
  padding: 2px;
}

#filter-wrapper  .filter-group-button.size .btn-size-wrapper {
  display: inline-block;
  width: 46px;
  height: 46px;
  margin-left: 2px;
  margin-top: 2px;
}

#filter-wrapper  .filter-group-button.size .btn-size-wrapper button {
  padding-left: initial !important;
  padding-right: initial !important;
  height: 100%;
}

#filter-wrapper .filter-group-button button.btn-snkrly {
  background-color: #fff !important;
  color: black !important;
  font-weight: initial;
  font-size: 13px !important;
}

#filter-wrapper .filter-group-button button.btn-snkrly:hover {
  background-color: #000 !important;
  color: white !important;
}

#filter-wrapper .filter-group-button button.btn-snkrly.active {
  background-color: #000 !important;
  color: white !important;
}

#filter-wrapper .filter-item .title {
  margin: 5px;
}

#filter-contianer .filter-block{
  margin-bottom: 15px;
}
#filter-contianer .filter-block:after,
#filter-contianer .filter-block:before{
  clear: both;
  display: table;
  content: " ";
}
#filter-contianer .filter-block .filter-title{
  font-weight: bold;
  margin: 5px;
}
#filter-contianer .btn.btn-snkrly{
  padding: 5px!important;
}
#shipping-filter .btn-snkrly{
  text-transform: uppercase;
}

#size-filter .btn.btn-snkrly{
  border-radius: 5px !important;
}
#filter-wrapper .col-xs-6, #shipping-filter .col-xs-6{
  width: 33.33%;
}

#filter-wrapper .clean-btn-wrapper, 
#filter-wrapper .close-btn-wrapper{
  display: inline-block;
}

#filter-wrapper .clean-btn-wrapper{
  float: left;
}

#filter-wrapper .close-btn-wrapper{
  float: right;
}


@media (hover: none) {
  .filter-group-button button.btn-snkrly:hover {
    background-color: inherit !important;
    color: inherit !important;
  }

  .filter-group-button button.btn-snkrly.active {
    background-color: #000 !important;
    color: white !important;
  }
}

@media only screen and (max-width: 1023px) {
  #column-filter {
    position: fixed;
    top: 0;
    right: 20px;
    bottom: 0;
    left: 100vw;
    margin-left: 0px;
    width: 100%;
    background: white;
    padding: 15px;
    overflow-y: auto;
    margin-right: -100%;
    z-index: 1000 !important;
    -webkit-transition: left .3s ease-in-out;
    -o-transition: left .3s ease-in-out;
    transition: left .3s ease-in-out;
  }

  #page-releases .btn-filter-wrapper-float {
    opacity: 0.9;
  }

  #row-container{
    justify-self: left;
  }
  #row-container, #column-filter, #release-filter {
    display: block;
    float: none !important;
    justify-content:unset;
    align-content: unset;
    align-items: unset;
    justify-items: unset;
    
  }
  #row-container.open-filters #release-filter{
    width: 100% !important;
  }
  #row-container.open-filters #column-filter{
    left:0vw;
  }
  
  body.filter-open{
    overflow: hidden;
  }

  #column-filter .filter-header {
    border-bottom: 1px solid rgb(222, 222, 222);
    margin-bottom: 15px;
    padding: 15px;
    text-align: center;
    position: relative;
    padding-top: 0px;
    position: relative;
    display: block;
  }

  #column-filter .filter-header .name,
  #column-filter .filter-header .close-btn-wrapper {
    line-height: inherit;
    text-align: center;
    padding: 0px;
  }

  #column-filter .filter-header .name {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    width: 100%;
    display: block;
  }

  #column-filter .filter-header .clean-btn-wrapper {
    width: auto;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 0px;
  }

  #column-filter .filter-header .close-btn-wrapper button {
    display: inline-block !important;
  }

  #release-header #sortByContainer #sltSortBy {
    width: auto;
  }

  #filter-wrapper {
    margin: 0 auto;
  }
  #filter-wrapper hr{
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1023px) {
  #filter-contianer{
    position: relative;
    padding-bottom: 30px;
  }
  .filter-header .clean-btn-wrapper{
    display: block;
  }

  .close-btn-wrapper{
    position: sticky;
    bottom: 0px;
    right: 0;
    overflow: hidden;
    float: right;
    left: auto;
    display: block;
  }
  #filter-wrapper .clean-btn-wrapper{
    display: none;
  }
}
