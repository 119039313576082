

body.slidebar-open{
    overflow-y: hidden;
}

#search-sidebar-wrapper #search-sidebar{
    background-color: rgb(255, 255, 255);
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10010;
    left: initial;
    margin-right: -40%;
    padding: 15px;
    width: 40%;
    display: none;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

}

#search-sidebar-wrapper.open #search-sidebar {
    display: block;
    margin-right: 0px;
}

#search-sidebar-wrapper .overlay {
    background-color: rgba(0, 0, 0);
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10000;
    margin-bottom: 0;
    -webkit-transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

#search-sidebar-wrapper.open .overlay {
    opacity: 0.6;
    display: flex;
    visibility: visible;
}

#search-sidebar .search-bar-form {
    width: 100%;
    padding: 0;
    border-bottom: 1px solid rgb(222, 222, 222);
    display: table;
    padding-bottom: 15px;
}

#search-sidebar .search-bar-form .search-bar-icon{
    float: left;
    font-size: 18px;
    width: 20px;
    height: 100%;
    line-height: 50px;
    text-align: center;
}

#search-sidebar .search-bar-form .search-bar-icon.hidden{
    display: none;
}
#search-sidebar .search-bar-form .form-group{
    margin: 0px;
    position: relative;
    display: table-cell;
}

#search-sidebar .search-bar-form input{
    border-width: 0px;
    border-style: initial;
    text-transform: uppercase;
    font-size: 18px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    height: 40px;
}

#search-sidebar .search-bar-form  input::placeholder{
    opacity: 0.6;
}

#search-sidebar .search-bar-form input:focus {
    outline: none;
}

#search-sidebar .btn-cancel-wrapper {
    display: none;
}
#search-sidebar .search-bar-form .input-group-btn{
    position: initial;
}
#search-sidebar .search-bar-form .input-group-btn button.btn{
    position: absolute;
    margin: 0px;
    height: 40px;
    width: 40px;
    padding: 0px;
    vertical-align: middle;
    text-align: center;
    top: 0px;
    font-size: 18px;
}
#search-sidebar .search-bar-form .input-group-btn button.btn.search-submit{
    left: 0px;
}
#search-sidebar .search-bar-form .input-group-btn button.btn.search-clear{
    right: 5px;
    
}

#search-sidebar .search-bar-form button.btn,
#search-sidebar .search-bar-form button.btn:active,
#search-sidebar .search-bar-form button.btn:hover,
#search-sidebar .search-bar-form button.btn:focus{
    border: none;
    background: none;
    box-shadow: none;
    -webkit-box-shadow: none;
}

#search-sidebar .loader {
    margin-top: 10px;
    height: 50px;
    width: 100%;
    text-align: center;
    line-height: 50px;
    font-size: 30px;
    position: absolute;
}

#search-sidebar .search-suggestion {
    padding: 0px;
    width: 100%;
}

#search-sidebar .search-suggestion .suggestion-container{
    margin: 15px 0;
}

#search-sidebar .search-suggestion .suggestion-container .title{
    color: rgb(129, 129, 129);
    border-bottom: 1px solid rgb(222, 222, 222);
    margin-bottom: 0px;
    font-size:18px;
    padding-bottom: 10px;
}

#search-sidebar .search-suggestion .suggestion-container .list-item {
    list-style-type: none;
    padding: 0;
    overflow: hidden;
    display: block;
}

#search-sidebar .search-suggestion .suggestion-container .list-item .item {
    border-bottom: 1px solid rgb(222, 222, 222);
    height: 50px;
    padding: 5px 0px;
    cursor: pointer;
    user-select: none; 
    line-height: 40px;
    text-transform: uppercase;
    display: block;
    overflow: hidden;
}

#search-sidebar .search-suggestion .suggestion-container .list-item .item::after {
    clear: both;
}

#search-sidebar .search-suggestion .suggestion-container .list-item .item > .name {
    float: left;
    width: calc(100% - 40px);
    font-size: 14px;
    font-weight: bold;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

#search-sidebar .search-suggestion .suggestion-container .list-item .item > .icon {
    float: right;
    /* width: 20px; */
    /* height: 100%; */
    display: block;
}

#search-sidebar .search-result .search-title {
    height: 70px;
    font-size: 0;
    line-height: 70px;
    border-bottom: 1px solid rgb(222, 222, 222);
    padding: 0px 10px;
    padding-right: 15px;
}

#search-sidebar .search-result .text {
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#search-sidebar .search-result .no-result-found {
    text-align: center;
    padding: 10px;
}

#search-sidebar .search-result .search-title::after {
    clear: both;
}

#search-sidebar .search-result .search-title *{
    font-size: 15px;
    padding:0 10px;
}

#search-sidebar .search-result .search-title .text{
    float: left;
    width: 50%;
    height: 100%;
    text-transform: uppercase;
}

#search-sidebar .search-result .search-title .number{
    float: left;
    width: 50%;
    text-align: right;
    font-size: 13px;
    color: rgb(129, 129, 129);
    height: 100%;
}

#search-sidebar .search-result .result-container {
    padding: 0px;
    margin-top: 40px;

}

#search-sidebar .search-result .result-container .title {
    font-size: 18px;
    border-bottom: 1px solid rgb(222, 222, 222);
    color: rgb(129, 129, 129);
    padding-bottom: 7px;
    margin-bottom: 0;
}

#search-sidebar .search-result .result-container .list-item {
    list-style-type: none;
    padding: 0;
    overflow: hidden;
}

#search-sidebar .search-result .result-container .list-item .item{
    border-bottom: 1px solid rgb(222, 222, 222);
    height: 65px;
    padding: 5px 0px;
    cursor: pointer;
    user-select: none; 
    display: block;
}

#search-sidebar .search-result .result-container .list-item .item .img-wrapper {
    height: 100%;
    line-height: 55px;
    float: left;
    width: 50px;
    display: block;
}
#search-sidebar .search-result .result-container .list-item .item .img-wrapper img {
    width: 100%;
    height: auto;
}

#search-sidebar .search-result .result-container .list-item .item > .name {
    float: left;
    width: calc(100% - 80px);
    line-height: 55px;
    padding-left: 15px;
    height: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    display: block;
}

#search-sidebar .search-result .result-container .list-item  .item > .icon {
    float: left;
    width: 20px;
    line-height: 55px;
    height: 100%;
    text-align: right;
}

.nav-icon-mobile {
    display: none;
    height: 50px;
    line-height: 50px;
    margin-right:20px;
    float: right;
    cursor: pointer;
}

.nav>li.nav-icon-desktop {
    cursor: pointer;
}
#search-desctop-icon{
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 20px;
    color: #262626;
    border-bottom: 1px solid transparent;
    padding-top: 17.5px;
    padding-bottom: 14.5px;
    transition: all 0.25s;
    
}
#search-mobile-icon, #search-desctop-icon{
    cursor: pointer;
}
@media (max-width: 767px) {
    .nav-icon-mobile {
        display: block;
    }
    #search-mobile-icon, #account-mobile-icon{
        display: inline-block;
        padding-left: 10px;
    }
    .nav>li.nav-icon-desktop {
        display: none;
    }
    .nav-icon-mobile.open .dropdown-menu{
        right: 0;
        left: unset;
    }

    #app .navbar.navbar-default .container .navbar-header .navbar-brand {
        float: none;
        display: inline-block;
    }

    #app .navbar.navbar-default .container .navbar-header .navbar-toggle {
        float: left;
    }    
    #search-sidebar-wrapper.open #search-sidebar{
        width: 100%;
        z-index: 10001;
    }    
    #search-sidebar-wrapper .overlay,
    #search-sidebar-wrapper.open .overlay {
        z-index: 10000;
    }

    #search-sidebar-wrapper.open #search-sidebar .btn-cancel-wrapper {
        text-align: center;
        cursor: pointer;
        vertical-align: middle !important;
        text-align: center;
        border-radius: 40px;
        padding: 8px;
        font-size: 14px;
        width: 40px;
        height: 40px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        position: fixed;
        overflow: hidden;
        bottom: 60px;
        left: 45.8%;
    }

    #search-sidebar .btn-cancel-wrapper button{
        width: 100%;
    }
    #search-sidebar .search-bar-form .search-bar-icon.remove {
        height: 100%;
        margin-right: 10px;
        float: left;
    }
}
